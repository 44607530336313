import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const RegistryPage = () => (
  <Layout>
    <Seo title="Thanks" />
    <h1>Thank you!</h1>
    <p>Thank you for RSVPing</p>

    <p>
      <Link to="/">Go back to the homepage</Link>
    </p>
  </Layout>
);

export default RegistryPage;
